<template>
  <th
    class="cell"
    @click="onClick"
  >
    <span class="content">
      <span class="title">
        <slot>{{ title }}</slot>
      </span>
      <span
        class="sort"
      >
        <base-icon
          v-if="sort === 'asc'"
          name="arrow-ios-downward-outline"
          size="18"
        />
        <base-icon
          v-if="sort === 'desc'"
          name="arrow-ios-upward-outline"
          size="18"
        />
      </span>
    </span>
  </th>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: String,
      default: '',
      validator(value) {
        // The value must match one of these strings
        return ['asc', 'desc', ''].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    onClick() {
      if (!this.sortable || !this.name) return;
      const direction = this.sort === 'asc' ? 'desc' : 'asc';
      this.$emit('sort', { column: this.name, direction });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: sticky;
  top: -1px;
  padding: 24px 0 24px 16px;
  font-size: 14px;
  text-align: left;
  background-color: $color-white;
  border-bottom: solid 1px $color-gray30;
  cursor: pointer;
}

.content {
  display: flex;
  align-items: center;
}

.sort {
  height: 18px;
  margin-left: 5px;
  vertical-align: middle;
}
</style>
