<template>
  <tr
    class="row"
    :class="{ 'row--dragging': isDragging }"
    draggable
    @dragstart="onDragStart"
    @dragend="onDragEnd"
    @click="$router.push({ name: 'employees.edit', params: { id } })"
  >
    <!-- <td>
      <input type="checkbox" />
    </td>-->
    <employees-table-cell>
      <base-status-light :type="statusName" />
    </employees-table-cell>
    <employees-table-cell>
      <text-highlight
        :queries="search"
      >
        {{ firstName }} {{ lastName }}
      </text-highlight>
    </employees-table-cell>
    <employees-table-cell>
      <text-highlight :queries="search">
        {{ email }}
      </text-highlight>
    </employees-table-cell>
    <employees-table-cell>
      <text-highlight
        v-if="team"
        :queries="search"
      >
        {{ team.name }}
      </text-highlight>
      <template v-else>
        (none)
      </template>
    </employees-table-cell>
  </tr>
</template>

<script>
/* Import Mutations */
import INVITE_EMPLOYEE_MUTATION from '@/graphql/mutations/InviteEmployee.gql';

/* Import Classes and Helpers */
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

/* Import Components */
import TextHighlight from 'vue-text-highlight';
import EmployeesTableCell from './EmployeesTableCell.vue';

export default {
  components: {
    EmployeesTableCell,
    TextHighlight,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    team: {
      type: Object,
      default: () => ({}),
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    status: {
      type: Object,
      default: () => ({}),
    },
    search: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isInviting: 0,
      isDragging: false,
      highlightStyle: {
        backgroundColor: '$color-secondary',
      },
    };
  },
  computed: {
    fullName() {
      return `${this.firstName} ${this.lastName}`;
    },
    statusName() {
      switch (this.status.id) {
        case '1':
          return 'added';
        case '2':
          return 'invited';
        case '3':
          return 'active';
        case '4':
          return 'inactive';
        default:
          return null;
      }
    },
  },
  methods: {
    async onInviteClick() {
      this.isInviting += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: INVITE_EMPLOYEE_MUTATION,
          variables: { id: this.id },
        })
        .then(() => {
          // Result
          this.$emit('invite', this.id);
          notifyFormSuccess(`${this.fullName} has been invited`);
        })
        .catch(() => {
          // Error
          notifyFormError(`${this.fullName} could not be invited`);
        })
        .finally(() => {
          this.isInviting -= 1;
        });
    },
    onDragStart(event) {
      this.isDragging = true;
      event.dataTransfer.setData('effectAllowed', 'move');
      event.dataTransfer.setData('text/plain', this.id);
    },
    onDragEnd() {
      this.isDragging = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  // cursor: move;
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }

  &--dragging {
    transform: translateX(-9999px);
  }
}
</style>
