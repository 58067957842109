<template>
  <ul class="list">
    <template v-if="loading">
      <li
        class="loading"
      >
        <base-skeleton-box />
      </li>
      <li class="divider" />
      <li
        v-for="index in 5"
        :key="index"
        class="loading"
      >
        <base-skeleton-box />
      </li>
    </template>
    <template v-else>
      <employees-team-list-item
        :employees="totalEmployees"
        :selected="!selected"
        name="All team members"
        class="everyone"
        @selected="$emit('update:selected')"
      />
      <li class="divider" />
      <employees-team-list-item
        v-for="team in teams"
        :id="team.id"
        :key="team.id"
        :name="team.name"
        :employees="team.employees_count"
        :warning="team.employees_count > 0 && !team.has_enough_employees"
        :selected="team.id === selected"
        @selected="$emit('update:selected', team.id)"
        @employee-moved="$emit('update')"
      />
      <li class="create">
        <base-button
          :to="{ name: 'employees.teams.create' }"
          type="primary"
          label="Create Team"
          icon="plus-outline"
          size="small"
        />
      </li>
    </template>
  </ul>
</template>

<script>
import EmployeesTeamListItem from './EmployeesTeamListItem.vue';

export default {
  components: {
    EmployeesTeamListItem,
  },
  props: {
    teams: {
      type: Array,
      default: null,
    },
    selected: {
      type: String,
      default: null,
    },
    totalEmployees: {
      type: Number,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 0;
  list-style-type: none;
}

.create {
  margin-top: 26px;
}

.divider {
  margin: 8px 0;
}

.loading {
  display: flex;
  align-items: center;
  height: 46px;
}
</style>
