<template>
  <th
    v-click-outside="hideDropdown"
    class="cell"
    @click="showDropdown = !showDropdown"
  >
    <div class="button">
      <base-status-light
        :type="selectedStatus ? selectedStatus.name.toLowerCase() : 'off'"
      />
    </div>
    <employees-status-list
      v-show="showDropdown"
      :statuses="statuses"
      :active-status-id="activeStatusId"
      class="list"
      @select="$emit('select-status', $event)"
    />
  </th>
</template>

<script>
import EmployeesStatusList from './EmployeesStatusList.vue';

export default {
  components: {
    EmployeesStatusList,
  },
  props: {
    statuses: {
      type: Array,
      default: () => [],
    },
    activeStatusId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    selectedStatus() {
      return this.statuses.find((status) => status.id === this.activeStatusId);
    },
  },
  methods: {
    hideDropdown() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  position: relative;
  position: sticky;
  top: -1px;
  z-index: 1;
  width: 1px;
  padding: 10px 0 10px 10px;
  text-align: left;
  background-color: $color-white;
  border-bottom: solid 1px $color-gray30;
}

.button {
  display: flex;
  align-items: center;
  padding: 6px;
  cursor: pointer;
}

.list {
  position: absolute;
  top: 56px;
  left: -10px;
  width: 200px;
  font-weight: normal;
}
</style>
