<template>
  <div class="empty">
    <div class="inner">
      <img
        src="~@/assets/images/graphic/teams.svg"
        class="graphic"
      >
      <base-paragraph>
        This team does not have any members yet. We care for the
        privacy of your employees, this is why
        <span class="strong">each team must contain at least {{ user.minimum_employees }}
          team members</span>.
      </base-paragraph>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  margin: 96px auto 0;
  text-align: center;
}

.graphic {
  width: 100%;
  max-width: 440px;
  margin-bottom: 48px;
}
</style>
