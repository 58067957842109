<template>
  <base-page
    v-if="!isFetchingInitial"
    class="page employees"
    title="Teams"
  >
    <template #aside>
      <template v-if="hasEmployeesOrTeams">
        <employees-search
          v-model="search"
          class="search"
          placeholder="Search..."
        />
        <employees-team-list
          :teams="teams"
          :total-employees="user ? user.employees_count : 0"
          :selected.sync="teamId"
        />
      </template>
    </template>

    <template
      #default
    >
      <employees-invite-all v-if="false" />

      <base-card>
        <employees-empty
          v-if="showCompanyEmpty"
          :user="user"
        />

        <div
          v-else-if="team"
        >
          <base-notice
            v-if="teamId && team.employees_count > 0 && !team.has_enough_employees"
            type="warning"
            icon="alert-triangle"
            message="This team will not be visible in your
              results because there are not enough acive users."
            button-label="why?"
          />
          <div class="header">
            <base-title
              :level="1"
              class="title"
            >
              <span class="title-label">
                {{ team.name }}
              </span>
            </base-title>
            <base-button
              v-if="teamId"
              class="edit"
              icon="edit-2-outline"
              type="ghost"
              size="large"
              link
              :to="{ name: 'employees.teams.edit', params: { id: teamId } }"
            />
            <base-button
              class="add"
              label="Add Team Member"
              :to="{ name: 'employees.create', params: { teamId} }"
              type="secondary"
              icon="plus-outline"
            />
          </div>

          <employees-team-empty
            v-if="showTeamEmpty"
            :user="user"
          />

          <div v-else>
            <employees-table
              v-if="filteredEmployees.length || hasFilters"
              :employees="filteredEmployees"
              :statuses="employeeStatuses"
              :active-status-id="employeeStatusId"
              :search="searchArray"
              :sort-by="sortBy"
              :sort-dir="sortDir"
              @sort="onTableSort"
              @select-status="employeeStatusId = $event"
            />
            <base-notice
              v-if="hasNoFilterResults"
              type="default"
              icon="info"
              :message="noFilterResultsMessage"
              button-label="clear filters"
              @button-click="clearFilters"
            />
          </div>
        </div>
      </base-card>
      <portal to="modal">
        <base-modal
          :open="showModal"
          @close="$router.push({ name: 'employees' })"
        >
          <router-view name="modal" />
        </base-modal>
      </portal>
    </template>
  </base-page>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import EMPLOYEES_QUERY from '@/graphql/queries/Employees.gql';
import TEAMS_QUERY from '@/graphql/queries/Teams.gql';
import EMPLOYEE_STATUSES_QUERY from '@/graphql/queries/EmployeeStatuses.gql';

/* Import Components */
import EmployeesEmpty from '@/components/modules/employees/EmployeesEmpty.vue';
import EmployeesSearch from '@/components/modules/employees/EmployeesSearch.vue';
import EmployeesTable from '@/components/modules/employees/EmployeesTable.vue';
import EmployeesTeamList from '@/components/modules/employees/EmployeesTeamList.vue';
import EmployeesTeamEmpty from '@/components/modules/employees/EmployeesTeamEmpty.vue';
import EmployeesInviteAll from '@/components/modules/employees/EmployeesInviteAll.vue';

/* Import Utils */
import EventBus from '@/utils/eventBus';
// import { createClient } from '@/utils/apollo';

export default {
  components: {
    EmployeesEmpty,
    EmployeesSearch,
    EmployeesTable,
    EmployeesTeamList,
    EmployeesTeamEmpty,
    EmployeesInviteAll,
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      showModal: !!this.$route.meta.showModal,
      user: null,
      teamId: this.$route.params.team,
      employeeStatusId: null,
      search: '',
      sortBy: 'name',
      sortDir: 'asc',
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
    },
    teams: {
      query: TEAMS_QUERY,
      variables() {
        return {
          user_id: this.userId,
        };
      },
    },
    employees: {
      query: EMPLOYEES_QUERY,
      variables() {
        return {
          user_id: this.userId,
        };
      },
    },
    employeeStatuses: {
      query: EMPLOYEE_STATUSES_QUERY,
      variables() {
        return {
          user_id: this.userId,
          team_id: this.teamId ? this.teamId : undefined,
        };
      },
    },
  },
  computed: {
    filteredEmployees() {
      if (!this.employees) return [];
      return this.employees.filter((employee) => {
        const fullName = `${employee.first_name.toLowerCase()} ${employee.last_name.toLowerCase()}`;
        const email = employee.email.toLowerCase();
        const teamName = employee.team ? employee.team.name.toLowerCase() : '';
        // Check if any of the entered search queries match the
        // full name, email and team name fields
        for (let i = 0; i < this.searchArray.length; i += 1) {
          const searchQuery = this.searchArray[i];
          // Remove where search filter does not match
          if (!fullName.includes(searchQuery)) {
            // The name filter does not match
            if (!email.includes(searchQuery)) {
              // The email filter does not match
              if (employee.team) {
                // The employee is in a team
                if (!teamName.includes(searchQuery)) {
                  // The team name filter does not match
                  return false;
                }
              } else {
                // The employee is not in a team
                return false;
              }
            }
          }
        }
        // Remove where employee status filter does not match
        if (this.employeeStatusId) {
          // We are filtering by employee status
          if (this.employeeStatusId !== employee.employee_status.id) {
            // This employee does not have the filtered status
            return false;
          }
        }
        // Remove where team filter does not match
        if (this.teamId) {
          // We are filtering by team
          if (employee.team) {
            // This employee is in a team
            if (this.teamId !== employee.team.id) {
              // This employee is not in the filtered team
              return false;
            }
          } else {
            // This employee is not in a team
            return false;
          }
        }
        return true;
      }).sort((a, b) => {
        // Set sort column
        let aString = a[this.sortBy];
        let bString = b[this.sortBy];
        // Set sort column exceptions
        if (this.sortBy === 'name') {
          aString = `${a.first_name} ${a.last_name}`;
          bString = `${b.first_name} ${b.last_name}`;
        } else if (this.sortBy === 'team') {
          aString = a.team ? a.team.name : '';
          bString = b.team ? b.team.name : '';
        }
        // Apply sort direction
        if (this.sortDir === 'desc') {
          return aString < bString ? 1 : -1;
        }
        return aString > bString ? 1 : -1;
      });
    },
    employeeStatus() {
      return this.employeeStatusId
        ? this.employeeStatuses.find((status) => status.id === this.employeeStatusId)
        : {};
    },
    team() {
      return this.teams && this.teamId
        ? this.teams.find((team) => team.id === this.teamId)
        : {
          id: null,
          name: 'All team members',
          employees_count: this.user ? this.user.employees_count : 0,
          added_employees_count: this.user ? this.user.added_employees_count : 0,
          invited_employees_count: this.user ? this.user.invited_employees_count : 0,
          active_employees_count: this.user ? this.user.active_employees_count : 0,
          inactive_employees_count: this.user ? this.user.inactive_employees_count : 0,
          has_enough_employees: this.user ? this.user.has_enough_employees : true,
        };
    },
    searchArray() {
      return this.search.toLowerCase().split(' ');
    },
    isFetchingInitial() {
      return (!this.user && this.$apollo.queries.user.loading)
        || (!this.employees && this.$apollo.queries.employees.loading);
    },
    showCompanyEmpty() {
      return !this.hasEmployees && !this.teamId;
    },
    showTeamEmpty() {
      return !this.filteredEmployees.length
         && this.team
         && !this.hasFilters
         && !this.$apollo.queries.user.loading;
    },
    hasEmployees() {
      return this.employees && this.employees.length > 0;
    },
    hasTeams() {
      return this.teams && this.teams.length > 0;
    },
    hasEmployeesOrTeams() {
      return this.hasEmployees || this.hasTeams;
    },
    hasFilters() {
      return this.search || this.employeeStatusId;
    },
    hasNoFilterResults() {
      return this.hasFilters && !this.filteredEmployees.length;
    },
    noFilterResultsMessage() {
      let message = 'No team members';
      if (this.employeeStatusId) {
        message += ` with status '${this.employeeStatus.name}'`;
      }
      message += ' found';
      if (this.search) {
        message += ` for search query '${this.search}'`;
      }
      message += '.';
      return message;
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$route.meta': function ({ showModal }) {
      this.showModal = !!showModal;
    },
  },
  mounted() {
    EventBus.$on('team-created', this.onTeamCreated);
    EventBus.$on('team-deleted', this.onTeamDeleted);
  },
  methods: {
    setData(user, employeeStatuses) {
      this.user = user;
      this.employee_statuses = employeeStatuses;
    },
    onTableSort(event) {
      this.sortBy = event.column;
      this.sortDir = event.direction;
    },
    onTeamCreated(team) {
      this.teamId = team.id;
    },
    onTeamDeleted() {
      this.teamId = null;
    },
    clearFilters() {
      this.search = '';
      this.employeeStatusId = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.employees {
  .title {
    margin-bottom: 0;
  }
}

.search {
  flex-grow: 1;
  margin-left: auto;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
}

.title {
  flex-shrink: 1;
  min-width: 0;
}

.title-label {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.edit {
  flex-shrink: 0;
  margin: 0 10px;
}

.count {
  margin-left: 8px;
}

.add {
  flex-shrink: 0;
  margin-left: auto;
}
</style>
