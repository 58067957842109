<template>
  <base-notice
    v-if="addedEmployeesCount"
    :button-label="addedEmployeesCount === 1 ? 'Send Invite' : 'Send Invites'"
    :button-loading="loading"
    @button-click="sendInvites"
  >
    {{ addedEmployeesCount }} {{ addedEmployeesCount === 1 ? "team member" : "team members" }}
    have not been invited yet.
  </base-notice>
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';

/* Import Mutations */
import INVITE_EMPLOYEE_MUTATION from '@/graphql/mutations/InviteEmployees.gql';

/* Import Utils */
import EventBus from '@/utils/eventBus';

/* Import Classes and Helpers */
import { notifyFormSuccess, notifyFormError } from '@/utils/notifications';

export default {
  data() {
    return {
      userId: this.$root.$data.userId,
      loading: false,
    };
  },
  apollo: {
    user: {
      query: USER_QUERY,
    },
  },
  computed: {
    addedEmployeesCount() {
      return this.user ? this.user.added_employees_count : 0;
    },
  },
  methods: {
    async sendInvites() {
      this.loading += 1;
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: INVITE_EMPLOYEE_MUTATION,
          variables: { user_id: this.userId },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
          ],
        })
        .then(() => {
          // Result
          EventBus.$emit('employees-invited');
          notifyFormSuccess('The invites have been sent out.');
        })
        .catch(() => {
          // Error
          notifyFormError('The invites could not be sent.');
        })
        .finally(() => {
          this.loading -= 1;
        });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
