<template>
  <tr class="row">
    <!-- <th> -->
    <!--Checkbox-->
    <!-- </th> -->
    <template v-for="column in columns">
      <employees-table-header-cell-status
        v-if="column.name === 'status'"
        :key="column.name"
        :statuses="statuses"
        :active-status-id="activeStatusId"
        :style="{ width: column.width }"
        @select-status="$emit('select-status', $event)"
      />
      <employees-table-header-cell
        v-else
        :key="column.name"
        :name="column.name"
        :title="column.title"
        :sortable="column.sortable"
        :sort="sortBy === column.name ? sortDir : ''"
        :style="{ width: column.width }"
        @sort="$emit('sort', $event)"
      />
    </template>
  </tr>
</template>

<script>
import EmployeesTableHeaderCell from './EmployeesTableHeaderCell.vue';
import EmployeesTableHeaderCellStatus from './EmployeesTableHeaderCellStatus.vue';

export default {
  components: {
    EmployeesTableHeaderCell,
    EmployeesTableHeaderCellStatus,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    activeStatusId: {
      type: String,
      default: null,
    },
    sortBy: {
      type: String,
      default: '',
    },
    sortDir: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  border-bottom: solid 1px $color-gray30;
  user-select: none;
}
</style>
