<template>
  <label class="search">
    <input
      :value="value"
      class="input"
      type="text"
      :placeholder="placeholder"
      @input="event => $emit('input', event.target.value)"
    >
    <base-icon
      v-if="value"
      class="close"
      name="close-outline"
      :size="20"
      @click="$emit('input', '')"
    />
    <base-icon
      v-else
      class="icon"
      name="search-outline"
      :size="20"
    />
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Search',
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  padding: 0 16px;
  color: $color-gray70;
  background-color: $color-white;
  border: solid 1px $color-gray30;
  border-radius: 6px;
  transition: box-shadow 200ms ease;

  &:focus-within {
    box-shadow: 0 8px 16px rgba(36, 26, 84, 0.075);
  }
}

.icon {
  flex-shrink: 0;
  margin-left: 10px;
}

.close {
  cursor: pointer;

  &:hover {
    color: $color-primary;
  }
}

.input {
  flex-grow: 1;
  width: 100%;
  min-width: 0;
  height: 40px;
  padding: 0;
  color: $color-text;
  font-size: 14px;
  font-family: $font-family-body;
  background: none;
  border: none;
  outline: none;
  appearance: none;

  &::placeholder {
    color: $color-gray70;
    opacity: 1;
  }
}
</style>
