<template>
  <base-dropdown class="list">
    <ul class="list-tag">
      <base-list-item
        label="All Statuses"
        :active="!activeStatusId"
        status="off"
        compact
        light
        class="list-item list-item--all"
        @click="$emit('select', null)"
      />
      <base-list-item
        v-for="status in statuses"
        :key="status.id"
        :label="status.name"
        :status="status.name.toLowerCase()"
        :badge="status.employees.paginatorInfo.total"
        :active="activeStatusId === status.id"
        :disabled="!status.employees.paginatorInfo.total"
        compact
        light
        class="list-item"
        @click="$emit('select', activeStatusId === status.id ? null : status.id)"
      />
    </ul>
  </base-dropdown>
</template>

<script>
export default {
  props: {
    statuses: {
      type: Array,
      default: () => [],
    },
    activeStatusId: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  padding: 12px;
}

.list-tag {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list-item {
  &--all {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: solid 1px $color-gray30;
  }
}
</style>
