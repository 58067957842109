<template>
  <td
    class="cell"
    :colspan="colspan"
  >
    <slot />
  </td>
</template>

<script>

export default {
  props: {
    colspan: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.cell {
  padding: 24px 0 24px 16px;
  background-color: $color-white;
  border-bottom: solid 1px $color-gray30;
}
</style>
