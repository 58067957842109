<template>
  <base-list-item
    class="list-item"
    :label="name"
    :badge="employees"
    :warning="warning"
    :active="selected"
    :hovered="isDragOver"
    @dragover="onDragOver"
    @dragleave="onDragLeave"
    @drop="onDrop"
    @click="$emit('selected')"
  />
</template>

<script>
/* Import Queries */
import USER_QUERY from '@/graphql/queries/User.gql';
import TEAMS_QUERY from '@/graphql/queries/Teams.gql';

/* Import Mutations */
import UPDATE_EMPLOYEE_MUTATION from '@/graphql/mutations/UpdateEmployee.gql';

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      required: true,
    },
    employees: {
      type: Number,
      required: true,
    },
    warning: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      userId: this.$root.$data.userId,
      isDragOver: false,
      originalId: this.id,
      minEmployees: 5,
    };
  },
  methods: {
    async moveEmployee(employeeId) {
      // GraphQL Mutation
      this.$apollo
        .mutate({
          mutation: UPDATE_EMPLOYEE_MUTATION,
          variables: {
            id: employeeId,
            team_id: this.id,
          },
          refetchQueries: [
            {
              query: USER_QUERY,
            },
            {
              query: TEAMS_QUERY,
              variables: {
                user_id: this.userId,
              },
            },
          ],
        });
    },
    onDragOver(event) {
      // Don't allow drop on 'All teams' item
      if (!this.id) return;
      // An employee is being dragged over this team
      this.isDragOver = true;
      // Set what effect to use when dropping the employee over this team
      event.dataTransfer.setData('dropEffect', 'move');
    },
    onDragLeave() {
      // Don't allow drop on 'All teams' item
      if (!this.id) return;
      // No employee is being dragged over this team anymore
      this.isDragOver = false;
    },
    onDrop(event) {
      event.preventDefault();
      // Don't allow drop on 'All teams' item
      if (!this.id) return;
      // No employee is being dragged over this team anymore
      this.isDragOver = false;
      // Get employee id from the dropped element
      const employeeId = event.dataTransfer.getData('text/plain');
      // Move dragged employee to this team
      this.moveEmployee(employeeId);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  position: relative;
  margin: 0 -8px;
}
</style>
