<template>
  <div class="table">
    <table class="table-tag">
      <thead class="table-head">
        <tr>
          <td :colspan="columns.length">
            <slot />
          </td>
        </tr>
        <employees-table-header-row
          :columns="columns"
          :statuses="statuses"
          :active-status-id="activeStatusId"
          :sort-by="sortBy"
          :sort-dir="sortDir"
          @select-status="$emit('select-status', $event)"
          @sort="$emit('sort', $event)"
        />
      </thead>
      <tbody
        class="table-body"
      >
        <template v-if="loading">
          <tr
            v-for="index in 10"
            :key="index"
            class="table-row__loading"
          >
            <employees-table-cell
              v-for="column in columns"
              :key="column.name"
            >
              <base-skeleton-box />
            </employees-table-cell>
          </tr>
        </template>
        <template v-else>
          <employees-table-row
            v-for="employee in employees"
            :id="employee.id"
            :key="employee.id"
            :team="employee.team"
            :first-name="employee.first_name"
            :last-name="employee.last_name"
            :email="employee.email"
            :status="employee.employee_status"
            :search="search"
            @delete="$emit('delete', $event)"
            @invite="$emit('invite', $event)"
            @click.native="$emit('edit', $event)"
          />
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
/* Import Components */
import EmployeesTableRow from './EmployeesTableRow.vue';
import EmployeesTableHeaderRow from './EmployeesTableHeaderRow.vue';
import EmployeesTableCell from './EmployeesTableCell.vue';

export default {
  components: {
    EmployeesTableRow,
    EmployeesTableHeaderRow,
    EmployeesTableCell,
  },
  props: {
    employees: {
      type: Array,
      default: null,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
    teamId: {
      type: String,
      default: null,
    },
    activeStatusId: {
      type: String,
      default: null,
    },
    search: {
      type: Array,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: '',
    },
    sortDir: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columns: [
        {
          name: 'status', title: '', sortable: false, width: '1px',
        },
        {
          name: 'name', title: 'Name', sortable: true, width: '33%',
        },
        {
          name: 'email', title: 'Email', sortable: true, width: '33%',
        },
        {
          name: 'team', title: 'Team', sortable: true, width: '33%',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.table {
  margin-bottom: 32px;
  // font-size: 14px;
}

.table-tag {
  width: 100%;
  border-spacing: 0;
}
</style>
