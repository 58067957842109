<template>
  <div class="empty">
    <div class="inner">
      <img
        src="~@/assets/images/graphic/teams.svg"
        class="graphic"
      >
      <base-title :level="1">
        Create Teams and Add Team Members
      </base-title>
      <base-paragraph>
        Simply add all team members directly, or create <span class="strong">teams</span>
        to compare results between different departments in your company. We care for the
        privacy of your employees, this is why
        <span class="strong">each team must contain at least {{ user.minimum_employees }}
          team members</span>.
      </base-paragraph>
      <base-group
        align="center"
        spacing="medium"
      >
        <base-button
          type="secondary"
          label="Add Team Members"
          :to="{ name: 'employees.create' }"
        />
        <base-button
          label="Create Teams"
          link
          :to="{ name: 'employees.teams.create' }"
        />
      </base-group>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 720px;
  margin: 0 auto;
  text-align: center;
}

.graphic {
  width: 100%;
  max-width: 440px;
  margin-bottom: 48px;
}
</style>
